import { routes } from './adminRoutes'


// ================================================================ Drawer ================================================================
const drawer = [
  {
    title: "داشبورد",
    icon: "mdi-view-dashboard",
    to: `/dashboard`,
  },

  {
    title: "ارز ها",
    icon: "mdi-bitcoin",
    to: `/coins`,
    api: {
      options: {
        method: "get",
        url: "/admin/coin/list",
      },
    },
  },

  {
    title: "کاربران",
    icon: "mdi-account-group",
    to: "/users",

    children: [
      {
        title: "گروه های کاربری",
        icon: "mdi-circle-small",
        to: `/users/groups`,
        api: {
          options: {
            method: "get",
            url: "/admin/role/list",
          },
        },
      },
      {
        title: "مشتریان",
        icon: "mdi-circle-small",
        to: `/users/customers`,
        api: {
          options: {
            method: "get",
            url: "/admin/customer/list",
          },
        },
      },
      {
        title: "سطوح احراز هویت",
        icon: "mdi-circle-small",
        to: `/users/levels`,
        api: {
          options: {
            method: "get",
            url: "/admin/customerlevel/list",
          },
        },
      },
      {
        title: "کارمندان",
        icon: "mdi-circle-small",
        to: `/users/employees`,
        api: {
          options: {
            method: "get",
            url: "/admin/employee/list",
          },
        },
      },
    ],
  },

  {
    title: "پشتیبانی تیکت",
    icon: "mdi-face-agent",
    to: `/ticketing`,
    children: [
      {
        title: "دپارتمان ها",
        icon: "mdi-circle-small",
        to: `/ticketing/ticketDepartment/`,
        api: {
          options: {
            method: "get",
            url: "/admin/ticketdepartment/list",
          },
        },
      },
      {
        title: "سوابق تیکت ها",
        icon: "mdi-circle-small",
        to: `/ticketing/ticket/`,
        api: {
          options: {
            method: "get",
            url: "/admin/ticket/list",
          },
        },
      },
    ]
  },
  
  {
    title: "سرمایه گذاری",
    icon: "mdi-currency-usd",
    to: `/staking`,
    children: [
      {
        title: "پلن های سرمایه گذاری",
        icon: "mdi-circle-small",
        to: `/staking/stakeplans/`,
        api: {
          options: {
            method: "get",
            url: "/admin/stakeplan/list",
          },
        },
      },
      {
        title: "سرمایه گذاری ها",
        icon: "mdi-circle-small",
        to: `/staking/stakes/`,
        api: {
          options: {
            method: "get",
            url: "/admin/stake/list",
          },
        },
      },
    ]
  },

  {
    title: "باشگاه مشتریان",
    icon: "mdi-account-group",
    to: `/fanclub`,
    children: [
      {
        title: "قرعه کشی ها",
        icon: "mdi-circle-small",
        to: `/fanclub/lottery/`,
        api: {
          options: {
            method: "get",
            url: "/admin/fanclub/list",
          },
        },
      },
      {
        title: "جشنواره ها",
        icon: "mdi-circle-small",
        to: `/fanclub/festival/`,
        api: {
          options: {
            method: "get",
            url: "/admin/festival/list",
          },
        },
      },
    ]
  },

  {
    title: "سوابق",
    icon: "mdi-clipboard-text-clock-outline",
    to: "/wallet",

    children: [
      {
        title: "سفارشات",
        icon: "mdi-circle-small",
        to: `/wallet/order`,
        api: {
          options: {
            method: "get",
            url: "/admin/order/list",
          },
        },
      },
      {
        title: "سفارشات سفارش گذاری",
        icon: "mdi-circle-small",
        to: `/wallet/preorder`,
        api: {
          options: {
            method: "get",
            url: "/admin/preorder/list",
          },
        },
      },
      {
        title: "سفارشات بازار حرفه ای",
        icon: "mdi-circle-small",
        to: `/wallet/preorder`,
        api: {
          options: {
            method: "get",
            url: "/admin/peertopeerorder/list",
          },
        },
      },
      {
        title: "سفارشات بازار P2P ریالی",
        icon: "mdi-circle-small",
        to: `/wallet/stableCoinP2pOrder`,
        api: {
          options: {
            method: "get",
            url: "/admin/stablecoinpeertopeerorder/list",
          },
        },
      },
      {
        title: "حواله های ارزی",
        icon: "mdi-circle-small",
        to: `/wallet/manualOrder`,
        api: {
          options: {
            method: "get",
            url: "/admin/manualOrder/list",
          },
        },
      },
      {
        title: "واریز",
        icon: "mdi-circle-small",
        to: `/wallet/deposit`,
        api: {
          options: {
            method: "get",
            url: "/admin/deposit/list",
          },
        },
      },
      {
        title: "برداشت",
        icon: "mdi-circle-small",
        to: `/wallet/withdraw`,
        api: {
          options: {
            method: "get",
            url: "/admin/withdraw/list",
          },
        },
      },
      {
        title: "تراکنش",
        icon: "mdi-circle-small",
        to: `/wallet/transaction`,
        api: {
          options: {
            method: "get",
            url: "/admin/transaction/list",
          },
        },
      },
    ],
  },

  {
    title: "تنظیمات",
    icon: "mdi-cog",
    to: "/settings",

    children: [
      {
        title: "صرافی",
        icon: "mdi-circle-small",
        to: `/settings/application`,
        api: {
          options: {
            method: "get",
            url: "/admin/applicationsetting/list",
          },
        },
      },
      {
        title: "فرمول قیمت",
        icon: "mdi-circle-small",
        to: `/settings/price`,
        api: {
          options: {
            method: "get",
            url: "/admin/pricesetting/detail",
          },
        },
      },
      {
        title: "درگاه های بانکی",
        icon: "mdi-circle-small",
        to: `/settings/gateways`,
        api: {
          options: {
            method: "get",
            url: "/admin/gateway/list",
          },
        },
      },
    ],
  },

  {
    title: "کارتابل",
    icon: "mdi-beaker-question-outline",
    to: "/jobs",

    children: [
      {
        title: "بررسی مدارک",
        icon: "mdi-circle-small",
        to: `/jobs/identities`,
        api: {
          options: {
            method: "get",
            url: "/admin/customeridentity/list",
          },
        },
      },
      {
        title: "بررسی حساب های بانکی",
        icon: "mdi-circle-small",
        to: `/jobs/bankaccounts`,
        api: {
          options: {
            method: "get",
            url: "/admin/bankaccount/list",
          },
        },
      },
      {
        title: "بررسی پروفایل",
        icon: "mdi-circle-small",
        to: `/jobs/profiles`,
        api: {
          options: {
            method: "get",
            url: "/admin/customerprofile/list",
          },
        },
      },
    ],
  },

  {
    title: "تسک ها",
    icon: "mdi-calendar-check-outline",
    to: "/settings",

    children: [
      {
        title: "تسک های واریز",
        icon: "mdi-circle-small",
        to: `/tasks/deposit`,
        api: {
          options: {
            method: "get",
            url: "/admin/deposittask/list",
          },
        },
      },
      {
        title: "تسک های برداشت",
        icon: "mdi-circle-small",
        to: `/tasks/withdraw`,
        api: {
          options: {
            method: "get",
            url: "/admin/withdrawtask/list",
          },
        },
      },
      {
        title: "تسک های ترید",
        icon: "mdi-circle-small",
        to: `/tasks/trade`,
        api: {
          options: {
            method: "get",
            url: "/admin/tradetask/list",
          },
        },
      },
      {
        title: "تسک های ترید بازار حرفه ای",
        icon: "mdi-circle-small",
        to: `/tasks/preordertrade`,
        api: {
          options: {
            method: "get",
            url: "/admin/preordertradetask/list",
          },
        },
      },
    ],
  },

  {
    title: "تامین ارز",
    icon: "mdi-cash",
    to: `/suppliment`,
    api: {
      options: {
        method: "get",
        url: "/admin/suppliment/list",
      },
    },
  },

  {
    title: "تبدیل ارز (swap)",
    icon: "mdi-cash",
    to: `/swap`,
    api: {
      options: {
        method: "get",
        url: "/admin/swap/list",
      },
    },
  },


  {
    title: "گزارشات",
    icon: "mdi-chart-line",
    to: "/settings",

    children: [
      {
        title: "موجودی سایت",
        icon: "mdi-circle-small",
        to: `/reports/balances`,
        api: {
          options: {
            method: "get",
            url: "/admin/report/balances",
          },
        },
      },
      {
        title: "سفارشات روزانه",
        icon: "mdi-circle-small",
        to: `/reports/dailyOrders`,
        api: {
          options: {
            method: "get",
            url: "/admin/report/dailyorders",
          },
        },
      },
      {
        title: "سود سفارشات OTC",
        icon: "mdi-circle-small",
        to: `/reports/profit`,
        api: {
          options: {
            method: "get",
            url: "/admin/report/profit",
          },
        },
      },
      {
        title: "سود روزانه سفارشات OTC",
        icon: "mdi-circle-small",
        to: `/reports/dailyProfit`,
        api: {
          options: {
            method: "get",
            url: "/admin/report/dailyprofit",
          },
        },
      },
      {
        title: "درآمد کارمزد سفارشات P2P",
        icon: "mdi-circle-small",
        to: `/reports/dailyFeeIncomes`,
        api: {
          options: {
            method: "get",
            url: "/admin/report/dailyfeeincomes",
          },
        },
      },
    ],
  },

  {
    title: "پروموشن",
    icon: "mdi-panorama-variant-outline",
    to: `/widgets/promotions`,
    api: {
      options: {
        method: "get",
        url: "/admin/promotion/list",
      },
    },
  },

  {
    title: "متون راهنما",
    icon: "mdi-text-recognition",
    to: `/helpMessages`,
    api: {
      options: {
        method: "get",
        url: "/admin/helpmessage/list",
      },
    },
  },
];

export { routes, drawer };
